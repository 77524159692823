import React from "react";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";
import { Accordion, AccordionItem } from '@szhsin/react-accordion';

import Card from "../common/card";

import "./styles/works.css";

const Works = () => {
	return (
		<div className="works">
			<Card
				icon={faBriefcase}
				title="Work"
				body={
					<div className="works-body">
						<div className="work">
							<img
								src="./business.png"
								alt="Turf Distributors"
								className="work-image"
							/>
							<div className="work-title">Turf Distributors <div className="work-duration">July 2023 - April 2024</div></div>
							<div className="work-subtitle">
								Multimedia Designer, Web Developer, SEO/Marketing Analyst
							</div>
							<Accordion>
								<AccordionItem header="Learn More">
									Developed a comprehensive suite of marketing assets including digital ads, social media graphics, and print materials. Assisted Lead designer in establishing and developing new  brand guidelines and ensured visual consistency across all marketing channels. Conducted a thorough SEO/SEM analysis of the entire corporation and presented aggregated monthly reports and live dashboards using  data visualization tools (Google Looker) to create actionable insights for the marketing team. Collaborated closely with marketing stakeholders to optimize campaign performance and deliver measurable results.
								</AccordionItem>
							</Accordion>
						</div>
						<div className="work">
							<img
								src="./business.png"
								alt="Freelance"
								className="work-image"
							/>
							<div className="work-title">Freelance <div className="work-duration">August 2012 - June 2023</div></div>
							<div className="work-subtitle">
								Full-Stack Engineer, Marketing Specialist, & Graphic Designer
							</div>
							<Accordion>
								<AccordionItem header="Learn More">
									Designed, developed, and pioneered the launches of many new websites powered by various backends such as WordPress, Magento, Drupal, Shopify, Laravel and more for agencies, sales teams, non-profits, consumer products,  manufacturers, and more located in the United States and abroad in China, Canada, and Europe.
								</AccordionItem>
							</Accordion>
						</div>
						<div className="work">
							<img
								src="./business.png"
								alt="Patio Heaven"
								className="work-image"
							/>
							<div className="work-title">Patio Heaven <div className="work-duration">July 2021 - November 2021</div></div>
							<div className="work-subtitle">
								Marketing Director
							</div>
							<Accordion>
								<AccordionItem header="Learn More">
									Responsible for planning, development, and implementation of a resilient marketing strategy and communications while identifying holes in past marketing efforts and making recommendations for future growth opportunities.
								</AccordionItem>
							</Accordion>
						</div>
						<div className="work">
							<img
								src="./business.png"
								alt="Boundless Technology"
								className="work-image"
							/>
							<div className="work-title">Boundless Technology <div className="work-duration">June 2020 – March 2021</div></div>
							<div className="work-subtitle">
								Marketing Director (Promotion)
							</div>
							<Accordion>
								<AccordionItem header="Learn More">
									Pioneered the launch of a new e-commerce website powered by WooCommerce, which dramatically improved the user experience for customers. I worked on a large part of the development and design of the website while managing a team of photographers, designers, and developers to make the vision a reality. Saw a 450% increase in direct business-to-consumer sales, met year-end goals early on, and projected one million in retail sales for 2021.
									Additionally served as the Lead Web Developer and oversaw E-commerce and System Administration.
								</AccordionItem>
							</Accordion>
						</div>
						<div className="work">
							<img
								src="./business.png"
								alt="Boundless Technology"
								className="work-image"
							/>
							<div className="work-title">Boundless Technology <div className="work-duration">April 2016 – June 2020</div></div>
							<div className="work-subtitle">
								Creative Director
							</div>
							<Accordion>
								<AccordionItem header="Learn More">
									Managed the creative process from concept to completion, including product design mockups, packaging/material sourcing, multilingual collateral and packaging, ios/android app design, website design, social/email campaigns, corporate branding, promotional material, sales pitches and proposals for commercial customers, catalogs, cyclical reports, trade show booth designs, training material, and promotional videos.
									Additionally served as the Lead Web Developer and oversaw E-commerce and System Administration.
								</AccordionItem>
							</Accordion>
						</div>
						<div className="work">
							<img
								src="./business.png"
								alt="ACU Web Services"
								className="work-image"
							/>
							<div className="work-title">ACU Web Services <div className="work-duration">March 2013 – April 2014</div></div>
							<div className="work-subtitle">
								Full-Stack Web Developer & Graphic Designer
							</div>
							<Accordion>
								<AccordionItem header="Learn More">
									Provided technical and creative solutions and strategies after understanding their business needs. Designed websites and web applications using Photoshop & Illustrator. Developed user-friendly client-maintainable website themes on various frameworks, including Joomla, Magento, and WordPress. Created reusable solutions for WordPress and Laravel to streamline our development processes. Prioritized and fixed site-related problems and took the initiative to implement new features based on feedback from clients. Performed server & website optimization and rigorously tested newly released software updates.
								</AccordionItem>
							</Accordion>
						</div>
					</div>
				}
			/>
		</div>
	);
};

export default Works;

import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

import { faMailBulk } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";

import Logo from "../components/common/logo";
import Footer from "../components/common/footer";
import NavBar from "../components/common/navBar";
import Works from "../components/homepage/works";

import INFO from "../data/user";
import SEO from "../data/seo";

import "./styles/homepage.css";

const Homepage = () => {
	const [stayLogo, setStayLogo] = useState(true);
	const [logoSize, setLogoSize] = useState(280); //350
	const [oldLogoSize, setOldLogoSize] = useState(280); //350

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		const handleScroll = () => {
			//let scroll = Math.round(window.pageYOffset, 2);

			let newLogoSize = 280//350 - (scroll * 4) / 5;

			if (newLogoSize < oldLogoSize) {
				if (newLogoSize > 280) {
					setLogoSize(newLogoSize);
					setOldLogoSize(newLogoSize);
					setStayLogo(true); //false
				} else {
					setStayLogo(true);
				}
			} else {
				setLogoSize(newLogoSize);
				setStayLogo(true); //false
			}
		};

		window.addEventListener("scroll", handleScroll);
		return () => window.removeEventListener("scroll", handleScroll);
	}, [logoSize, oldLogoSize]);

	const currentSEO = SEO.find((item) => item.page === "home");

	const logoStyle = {
		display: "flex",
		position: stayLogo ? "fixed" : "relative",
		top: stayLogo ? "4.6vh" : "4.6vh",
		zIndex: 999
	};

	return (
		<React.Fragment>
			<Helmet>
				<title>{INFO.main.title}</title>
				<meta name="description" content={currentSEO.description} />
				<meta
					name="keywords"
					content={currentSEO.keywords.join(", ")}
				/>
			</Helmet>

			<div className="page-content">
				<NavBar active="home" />
				<div className="content-wrapper">
					<div className="homepage-logo-container">
						<div style={logoStyle}>
							<Logo width={logoSize} link={false} />
						</div>
					</div>

					<div className="homepage-container">
						<div className="homepage-first-area">
							<div className="homepage-first-area-left-side">
								<div className="title homepage-title">
									Your Expert in <br />Design and Marketing
								</div>
								<div className="badges">
									<span className="badge-opentowork">Open to Work</span><span className="badge-location"><FontAwesomeIcon icon={faLocationDot} /> Orange County, CA or Remote</span>
								</div>
								<div className="homepage-socials">
									<a
										href={INFO.socials.linkedin}
										target="_blank"
										rel="noreferrer"
									>
										<FontAwesomeIcon
											icon={faLinkedin}
											className="homepage-social-icon"
										/>
									</a>
									<a
										href={`mailto:${INFO.main.email}`}
										target="_blank"
										rel="noreferrer"
									>
										<FontAwesomeIcon
											icon={faMailBulk}
											className="homepage-social-icon"
										/>
									</a>
								</div>
								<div className="subtitle homepage-subtitle">
									{INFO.homepage.description}
								</div>
							</div>

							<div className="homepage-first-area-right-side">
								<div className="homepage-image-container">
									<div className="homepage-image-wrapper">
										<img
											src="homepage.jpg"
											alt="about"
											className="homepage-image"
										/>
									</div>
								</div>
							</div>
						</div>
						{/*
						<div className="homepage-projects">
							<AllProjects />
						</div>
						*/}
						<div className="homepage-after-title">
							<div className="homepage-works">
								<Works />
							</div>
							<div className="homepage-about-2">
								<h2>From Canvas <br />to Code</h2>
								<p>As a highly organized and motivated marketing professional, I bring over 8 years of experience in marketing and 12 years in web development. I’ve always been drawn to art and creativity from a young age, and graphic design felt like a natural path for me because it combines artistic expression with practical application. I love how graphic design allows me to turn ideas into visual realities that can communicate, inspire, and connect with people. There's something incredibly satisfying about starting with a blank canvas and transforming it into a design that tells a story or solves a problem.</p>
							</div>
							<div className="homepage-about-3">
								<h2>Passionate About Learning and Adapting</h2>
								<p>I continuously embrace new technologies and techniques to enhance my skills and optimize processes. I specialize in blending creative and technical perspectives, offering a unique ability to craft and express corporate brands effectively, design data-driven campaigns, and translate complex marketing efforts into clear, actionable insights. </p>
							</div>
							<div className="homepage-skills">
								
								<div className="title">
									<h2><FontAwesomeIcon icon={faUser} /> Skills</h2>
								</div>
								<div className="row">
									<div className="item">
									<div className="item-text">
										<span>Marketing Data Analysis</span>
										<span className="w-100">100%</span>
									</div>
									<div className="progress">
										<div className="progress-bar w-100"></div>
									</div>
									</div>

									<div className="item">
									<div className="item-text">
										<span>Data Visualization</span>
										<span className="w-100">100%</span>
									</div>
									<div className="progress">
										<div className="progress-bar w-100"></div>
									</div>
									</div>

									<div className="item">
									<div className="item-text">
										<span>SEM/SEO Marketing</span>
										<span className="w-100">100%</span>
									</div>
									<div className="progress">
										<div className="progress-bar w-100"></div>
									</div>
									</div>

									<div className="item">
									<div className="item-text">
										<span>Integrated Marketing</span>
										<span className="w-100">100%</span>
									</div>
									<div className="progress">
										<div className="progress-bar w-100"></div>
									</div>
									</div>

									<div className="item">
									<div className="item-text">
										<span>Conversion Rate Optimization</span>
										<span className="w-100">100%</span>
									</div>
									<div className="progress">
										<div className="progress-bar w-100"></div>
									</div>
									</div>

									<div className="item">
									<div className="item-text">
										<span>Graphic & Web Design</span>
										<span className="w-100">100%</span>
									</div>
									<div className="progress">
										<div className="progress-bar w-100"></div>
									</div>
									</div>

									<div className="item">
									<div className="item-text">
										<span>Web Development</span>
										<span className="w-100">100%</span>
									</div>
									<div className="progress">
										<div className="progress-bar w-100"></div>
									</div>
									</div>

									<div className="item">
									<div className="item-text">
										<span>Marketing Direction</span>
										<span className="w-100">100%</span>
									</div>
									<div className="progress">
										<div className="progress-bar w-100"></div>
									</div>
									</div>

									<div className="item">
									<div className="item-text">
										<span>Creative Direction</span>
										<span className="w-100">100%</span>
									</div>
									<div className="progress">
										<div className="progress-bar w-100"></div>
									</div>
									</div>
								</div>
							</div>
						</div>
						<div class="cta"><h2>Want to Learn More?</h2>Download a Copy of My Resume <a href="https://tycreate.app/Tyler-Bergman-Resume.pdf" rel="noreferrer" target="_blank"><div className="button">Download Resume</div></a></div>
						<div className="page-footer">
							<Footer />
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Homepage;

import { useState } from 'react';
import emailjs from '@emailjs/browser';


export const ContactForm = () => {
    const form = useState();
    const [stateMessage, setStateMessage] = useState(null);

    const sendEmail = (e) => {
        e.persist();
        e.preventDefault();

        emailjs
        .sendForm('tycreate.app', 'tycreate.app.default', form.current, {
            publicKey: 'lvvXDdAQJu-HMnvYR',
        })
        .then(
            () => {
                e.target.reset(); //Clear form fields
                setStateMessage('Message sent!');
                setTimeout(() => {
                    setStateMessage(null);
                }, 5000); // hide message after 5 seconds
                console.log('Email Sent!');
            },
            (error) => {
                setStateMessage('Something went wrong, please try again later');
                setTimeout(() => {
                    setStateMessage(null);
                }, 5000); 
                console.log('Failed to send email:', error.text);
            },
        );
    };

    return (
        <form ref={form} onSubmit={sendEmail}>
            {stateMessage && <p className="contact-form-state">{stateMessage}</p>}
            <label>Name</label>
            <input type="text" name="user_name" />
            <label>Email</label>
            <input type="email" name="user_email" />
            <label>Message</label>
            <textarea name="message" />
            <input type="submit" value="Send" />
        </form>
    );
};
export default ContactForm;
const INFO = {
	main: {
		title: "Tyler Bergman | Your Expert in Design and Marketing",
		name: "Tyler Bergman",
		email: "tylerabergman@gmail.com",
		logo: "../logo.png",
	},

	socials: {
		linkedin: "https://linkedin.com/in/tyler-bergman/",
	},

	homepage: {
		title: "Your Expert in Design and Marketing",
		description:
			"Results-oriented marketing professional with a strong foundation in design and web development. Over 12 years of experience translating complex data into actionable insights and crafting compelling visual narratives. Proven ability to drive brand growth and deliver measurable results.",
	},

	about: {
		title: "It's great to meet you!",
		description:
			"As a highly organized and motivated marketing professional, I bring over 8 years of experience in marketing and 14 years in web development. My diverse background allows me to see the bigger picture and understand the nuanced needs of both clients and team members. \n\nI specialize in blending creative and technical perspectives, offering a unique ability to craft and express corporate brands effectively, design data-driven campaigns, and translate complex marketing efforts into clear, actionable insights. My proficiency in creating visually compelling data visualizations and reports ensures that stakeholders are always informed with easy-to-digest information. \n\nPassionate about learning and adapting, I continuously embrace new technologies and techniques to enhance my skills and optimize processes. I thrive in challenging, fast-paced environments where collaboration, competition, and meeting deadlines are the norms.",
	},

	articles: {
		title: "I'm passionate about pushing the boundaries of what's possible and inspiring the next generation of innovators.",
		description:
			"Chronological collection of my long-form thoughts on programming, leadership, product design, and more.",
	},

	projects: [
		{
			title: "Project 1",
			description:
				"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			linkText: "View Project",
			link: "https://github.com",
		},

		{
			title: "Project 2",
			description:
				"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/python/python.png",
			linkText: "View Project",
			link: "https://github.com",
		},

		{
			title: "Project 3",
			description:
				"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/html/html.png",
			linkText: "View Project",
			link: "https://github.com",
		},

		{
			title: "Project 4",
			description:
				"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			linkText: "View Project",
			link: "https://github.com",
		},

		{
			title: "Project 5",
			description:
				"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			linkText: "View Project",
			link: "https://github.com",
		},
	],
};

export default INFO;

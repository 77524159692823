const SEO = [
	{
		page: "home",
		description:
			"Your Expert in Design and Marketing. Results-oriented marketing professional with a strong foundation in design and web development. Over 12 years of experience translating complex data...",
		keywords: ["Tyler Bergman", "Tyler B", "tycreate"],
	},

	{
		page: "about",
		description:
			"As a highly organized and motivated marketing professional, I bring over 8 years of experience in marketing and 14 years in web development. My diverse background allows me to...",
		keywords: ["Tyler Bergman", "Tyler B", "tycreate"],
	},

	{
		page: "articles",
		description:
			"Chronological collection of my long-form thoughts on programming, leadership, product design, and more.",
		keywords: ["Tyler Bergman", "Tyler B", "tycreate"],
	},

	{
		page: "projects",
		description:
			"I've worked on a variety of projects over the years and I'm proud of the progress I've made. Many of these projects are open-source and available for others to explore and contribute to.",
		keywords: ["Tyler Bergman", "Tyler B", "tycreate"],
	},

	{
		page: "contact",
		description:
			"If you're interested in collaborating on a project, feel free to reach out to me. I'm always open to new ideas and opportunities.",
		keywords: ["Tyler Bergman", "Tyler B", "tycreate"],
	},
];

export default SEO;

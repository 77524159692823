import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import NavBar from "../components/common/navBar";
import Footer from "../components/common/footer";
import Logo from "../components/common/logo";
import Socials from "../components/about/socials";

import INFO from "../data/user";
import SEO from "../data/seo";

import "./styles/about.css";

const About = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const currentSEO = SEO.find((item) => item.page === "about");

	return (
		<React.Fragment>
			<Helmet>
				<title>{`About | ${INFO.main.title}`}</title>
				<meta name="description" content={currentSEO.description} />
				<meta
					name="keywords"
					content={currentSEO.keywords.join(", ")}
				/>
			</Helmet>

			<div className="page-content">
				<NavBar active="about" />
				<div className="content-wrapper">
					<div className="about-logo-container">
						<div className="about-logo">
							<Logo width={280} />
						</div>
					</div>

					<div className="about-container">
						<div className="about-main">
							<div className="about-right-side">
								<div className="title about-title">
									{INFO.about.title}
								</div>

								<div className="subtitle about-subtitle">
								I’ve always been drawn to art and creativity from a young age, and graphic design felt like a natural path for me because it combines artistic expression with practical application. I love how graphic design allows me to turn ideas into visual realities that can communicate, inspire, and connect with people. There's something incredibly satisfying about starting with a blank canvas and transforming it into a design that tells a story or solves a problem.
								<br /><br />
								What I enjoy most about this work is the creative problem-solving aspect—finding the perfect balance between aesthetics and functionality. I thrive on the challenge of creating designs that not only look great but also serve a purpose, whether it's building a brand identity, enhancing user experience, or delivering a clear message.
								<br /><br />
								I also appreciate the collaborative nature of graphic design, working with clients and teams to bring their visions to life. Seeing the impact of my work and knowing it helps convey a message or achieve a goal is what keeps me passionate about this field. It’s the blend of creativity, strategy, and impact that makes graphic design the perfect fit for me.
								<br /><br />
								As a highly organized and motivated marketing professional, I bring over 8 years of experience in marketing and 14 years in web development. My diverse background allows me to see the bigger picture and understand the nuanced needs of both clients and team members. 
								<br /><br />
								I specialize in blending creative and technical perspectives, offering a unique ability to craft and express corporate brands effectively, design data-driven campaigns, and translate complex marketing efforts into clear, actionable insights. My proficiency in creating visually compelling data visualizations and reports ensures that stakeholders are always informed with easy-to-digest information. 
								<br /><br />
								Passionate about learning and adapting, I continuously embrace new technologies and techniques to enhance my skills and optimize processes. I thrive in challenging, fast-paced environments where collaboration, competition, and meeting deadlines are the norms.
								</div>
							</div>

							<div className="about-left-side">
								<div className="about-image-container">
									<div className="about-image-wrapper">
										<img
											src="about.jpg"
											alt="about"
											className="about-image"
										/>
									</div>
								</div>

								<div className="about-socials">
									<Socials />
								</div>
							</div>
						</div>
						<div className="about-socials-mobile">
							<Socials />
						</div>
					</div>
					<div className="page-footer">
						<Footer />
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default About;
